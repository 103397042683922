//
import RCatalogueService from '@/services/api/Recommendation/RCatalogue'

//
export default {
  data() {
    return {
      busy: {
        explore: false,
      },
      recommendationWidth: 250,
      rCatalogue: [],
    }
  },
  filters: {

    /**
     * 
     */
    style(provider) {
      return `background-image: url('data:image/jpeg;base64,${ provider.smallLogo }');`
    }
  },
  methods: {

    /**
     * 
     */
    async getCatalogue() {
      this.busy.explore = true
      // await RCatalogueService.getExploreDefault().then(res => {
      await RCatalogueService.getExplore().then(res => {
        // console.log({ res })
        this.rCatalogue = res.data
      })
      this.busy.explore = false
    },

    /**
     * 
     */
    handleProviderClick(provider) {
      this.$router.push(`/proveedor/${ btoa(`PROVIDER-${ provider.id }`) }`)
    },

    /**
     * 
     */
    handleWindowResize() {
      let ctrWidth = document.getElementById('explore').offsetWidth
      let rowWidth = ctrWidth - 180
      let elmWidth = Math.round(rowWidth / 4)
      let resWidth = Math.max(150, elmWidth)
      this.recommendationWidth = resWidth
    }
  },
  mounted() {
    this.getCatalogue()
    this.handleWindowResize()
    window.addEventListener('resize', this.handleWindowResize)
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize)
  }
}