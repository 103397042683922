// required modules
import Model from '@/models/Model'

/**
 * This class describes a RCatalogue model.
 * @class RCatalogue
 */
export default class RCatalogueModel extends Model {

  /**
   * Define the RCatalogue resource.
   * @return {String} RCatalogue endpoint name.
   */
  static resource() {
    return 'catalogue'
  }
}